import React from "react";
import {
	CheckCircleIcon,
	DotsHorizontalIcon, EyeIcon,
	InformationCircleIcon,
	ShieldExclamationIcon, UserRemoveIcon
} from "@heroicons/react/solid";
import {Button, ButtonGroup, Card, Dropdown, Image, Table} from "react-bootstrap";

const capitalizeFirstLetter = (string) => (
	string[0].toUpperCase() + string.slice(1)
);

const getFirstLetterOfEachWord = (text) => (
	text.match(/\b\w/g).join('')
);

export const UsersTable = (props) => {
	const { users = [] } = props;

	const TableRow = (props) => {
		const { verified, status, image, name, email, dateCreated } = props;
		const VerifiedIcon = verified ? CheckCircleIcon : InformationCircleIcon;
		const statusVariant = status === "active" ? "success"
			: status === "inactive" ? "warning"
				: status === "pending" ? "purple"
					: status === "suspended" ? "danger" : "primary";

		return (
			<tr className="border-bottom">
				<td>
					<Card.Link className="d-flex align-items-center">
						{image
							? (
								<Image
									src={image}
									className="avatar rounded-circle me-3"
								/>
							) : (
								<div className="avatar d-flex align-items-center justify-content-center fw-bold rounded bg-secondary me-3">
									<span>{getFirstLetterOfEachWord(name)}</span>
								</div>
							)}
						<div className="d-block">
							<span className="fw-bold">{name}</span>
							<div className="small text-gray">{email}</div>
						</div>
					</Card.Link>
				</td>
				<td><span className="fw-normal">{dateCreated}</span></td>
				<td>
          <span className="fw-normal d-flex align-items-center">
            <VerifiedIcon className={`icon icon-xxs text-${statusVariant} me-1`} />
            Email
          </span>
				</td>
				<td>
          <span className={`fw-normal text-${statusVariant}`}>
            {capitalizeFirstLetter(status)}
          </span>
				</td>
				<td>
					<Dropdown as={ButtonGroup}>
						<Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
							<DotsHorizontalIcon className="icon icon-xs" />
						</Dropdown.Toggle>
						<Dropdown.Menu className="dashboard-dropdown dropdown-menu-start mt-2 py-1">
							<Dropdown.Item className="d-flex align-items-center">
								<ShieldExclamationIcon className="dropdown-icon text-gray-400 me-2" />
								Reset Pass
							</Dropdown.Item>
							<Dropdown.Item className="d-flex align-items-center">
								<EyeIcon className="dropdown-icon text-gray-400 me-2" />
								View Details
							</Dropdown.Item>
							<Dropdown.Item className="d-flex align-items-center">
								<UserRemoveIcon className="dropdown-icon text-danger me-2" />
								Suspend
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>

					{/*<OverlayTrigger placement="top" overlay={<Tooltip className="m-0">Delete</Tooltip>}>*/}
					{/*    <Card.Link className="ms-2" onClick={() => deleteUsers([id])}>*/}
					{/*        <XCircleIcon className="icon icon-xs text-danger" />*/}
					{/*    </Card.Link>*/}
					{/*</OverlayTrigger>*/}
				</td>
			</tr>
		);
	};

	return (
		<Card border="0" className="table-wrapper table-responsive shadow">
			<Card.Body>
				<Table hover className="user-table align-items-center">
					<thead>
					<tr>
						<th className="border-bottom">Name</th>
						<th className="border-bottom">Date Created</th>
						<th className="border-bottom">Verified</th>
						<th className="border-bottom">Status</th>
						<th className="border-bottom">Action</th>
					</tr>
					</thead>
					<tbody className="border-0">
					{users.map(u => <TableRow key={`user-${u.id}`} {...u} />)}
					</tbody>
				</Table>
			</Card.Body>
		</Card>
	);
};
