import React, {useEffect, useState} from 'react';
import Table from "react-bootstrap-table-next";
import Pagination from "react-bootstrap-table2-paginator";
import * as Paginator from "react-bootstrap-table2-paginator";
import {Col, Row, Card, Form, Button} from 'react-bootstrap';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import {Toasts} from 'components/Toast';
import {useHistory, useLocation} from "react-router-dom";
import {PencilAltIcon} from "@heroicons/react/solid";

import CreateCardPaymentModal from "../components/CreateCardPaymentModal";
import {request} from "../services/http.service";
import entries from "data/transactions";

const columns = [
	{ dataField: "id", text: "ID", hidden: true },
	{ dataField: "invoiceNumber", text: "Invoice" },
	{ dataField: "status", text: "status" },
	{ dataField: "subscription", text: "Subscription", hidden: true},
	{ dataField: "amount", text: "Amount", formatter: (cell) => <span>${cell}</span> },
	{ dataField: "issueDate", text: "Started" },
	{ dataField: "dueDate", text: "Completed" },
];

const customTotal = (from, to, size) => (
	<div>
		Showing {from} to {to} of {size} entries
	</div>
);

const customSizePerPage = (props) => {
	const { options, currentSizePerPage, onSizePerPageChange } = props;

	const onPageChange = (e) => {
		const page = e.target.value;
		onSizePerPageChange(page);
	}

	return (
		<Row>
			<Col xs="auto">
				<Form.Select value={currentSizePerPage} onChange={onPageChange} className="pe-5">
					{options.map(o => (
						<option key={o.page} value={o.page}>
							{o.text}
						</option>
					))}
				</Form.Select>
			</Col>
			<Col xs="auto" className="d-flex align-items-center">
				entries per page
			</Col>
		</Row>
	)
};

export default () => {
	const history = useHistory();
	const [transactions, setTransactions] = useState(entries);
	const [toastSettings, setToastSettings] = useState({});
	const [showToast, setShowToast] = useState(false);
	const [showCreatePayinModal, setShowCreatePayinModal] = useState(false)

	const openCardPaymentModal = () => setShowCreatePayinModal(true)
	const closeCardPaymentModal = () => setShowCreatePayinModal(false)
	const handleCloseToast = () => setShowToast(false);

	const query = new URLSearchParams(useLocation().search);
	const success = query.get('success')
	const fail = query.get('fail')

	useEffect(() => {
		if (success || fail) {
			const text = success ? 'succeed.' : 'failed.'
			setToastSettings({
				message: 'Transaction ' + text,
				background: success ? 'success' : 'danger'
			});
			setShowToast(true);
			const newQuery = new URLSearchParams(query);
			newQuery.delete('success');
			history.replace({
				search: newQuery.toString()
			});
		}
	}, [query, history]);

	const handleCreateCardPayment = async (newPayment) => {
		const data = await createPayment(newPayment)
		closeCardPaymentModal();

		setToastSettings({
			message: 'Payin created',
			background: 'success'
		});

		setShowToast(true);
	}

	const createPayment = async (data) => {
		const payin = await request.post({
			path: `public/transactions/create-payin`,
			data
		})
		window.open(payin.data, '_blank', 'noopener,noreferrer');
	}
	const startCreatePayment = async (data) => {
		openCardPaymentModal()
	}

	return (
		<>
		<ToolkitProvider
			keyField="id"
			search={true}
			columns={columns}
			data={transactions}
			wrapperClasses="table-responsive"
		>
			{({ baseProps, searchProps }) => (
				<Paginator.PaginationProvider pagination={
					Pagination({
						custom: true,
						showTotal: true,
						alwaysShowAllBtns: true,
						totalSize: transactions.length,
						paginationTotalRenderer: customTotal,
						sizePerPageRenderer: customSizePerPage
					})
				}>
					{({ paginationProps, paginationTableProps }) => (
						<Card>
							<div className="table-responsive py-2">
								<Card.Header>
									<Row>
										<Col xs={12} md={6} className="py-1">
											<Paginator.SizePerPageDropdownStandalone {...paginationProps} />
										</Col>
										<Col xs={12} md={6} className="d-flex justify-content-md-end py-1">
											<Search.SearchBar {...searchProps} />
										</Col>
									</Row>
								</Card.Header>

								<div>
									<Button variant="secondary" className="d-inline-flex align-items-center mb-3 mb-md-0"
													onClick={startCreatePayment}>
										<PencilAltIcon className="icon icon-xs me-2" />
										Create pay in
									</Button>
								</div>

								<Table
									{...baseProps}
									{...paginationTableProps}
									bodyClasses="border-0"
									rowClasses="border-bottom"
									classes="table-flush dataTable-table"
								/>

								<Card.Footer>
									<Row>
										<Col xs={12} md={6} className="d-flex align-items-center py-1">
											<Paginator.PaginationTotalStandalone {...paginationProps} />
										</Col>
										<Col xs={12} md={6} className="d-flex justify-content-md-end align-items-center mb-0 py-1">
											<Paginator.PaginationListStandalone {...paginationProps} />
										</Col>
									</Row>
								</Card.Footer>
							</div>
						</Card>
					)}
				</Paginator.PaginationProvider>
			)}
		</ToolkitProvider>
			{ showCreatePayinModal && <CreateCardPaymentModal
				handleAddWallet={handleCreateCardPayment}
				closeModal={closeCardPaymentModal}
			/>}
			{
				showToast && <Toasts
					settings={toastSettings}
					handleCloseToast={handleCloseToast}
				/>
			}
		</>
	)
}
