import axios from "axios";

import config from '../config';

let authToken = localStorage.getItem('token');

export function setAuthToken(newToken) {
  localStorage.setItem('token', newToken);

  return authToken = newToken;
}

function getHeaders(headers) {
  const defaultHeaders = {
    'Access-Control-Allow-Origin': config.apiUrl,
    'x-api-key': authToken,
    'Content-Type': 'application/json',
  }

  return headers
    ? {...defaultHeaders, ...headers}
    : defaultHeaders
}

function createRequestParams({path, data, headers}) {
  const result = [];

  result.push(path);
  if(data)result.push(data);
  result.push({headers: getHeaders(headers)});

  return result;
}

const axiosInstance = axios.create({
  baseURL: config.apiUrl,
});

function createRequestWithBody(requestType) {
  return async (params) => {
    const requestParams = createRequestParams(params);

    return axiosInstance[requestType](...requestParams);
  }
}

export const request = {
  get: createRequestWithBody('get'),
  post: createRequestWithBody('post'),
  put: createRequestWithBody('put'),
  patch: createRequestWithBody('patch'),
  delete: createRequestWithBody('delete'),
}