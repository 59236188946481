import React, {useState} from "react";
import {Card, Col, Form, Row} from "react-bootstrap";

export const CompanyInfoForm = ({company}) => {
	const defaultValue = '';
	const disabled = true;

	const [name] = useState(company.name);
	const [registrationNumber] = useState(company.registrationNumber || defaultValue);
	const [email] = useState(company.email || defaultValue);
	const [phone] = useState(company.phone || defaultValue);
	// address
	const [street] = useState(company.address?.street || defaultValue);
	const [streetNumber] = useState(company.address?.streetNumber || defaultValue);
	const [city] = useState(company.address?.city || defaultValue);
	const [country] = useState(company.address?.country || '0');
	const [zipCode] = useState(company.address?.zipCode || defaultValue);

	return (
		<Card border="0" className="shadow mb-4">
			<Card.Body>
				<h5 className="mb-4">General information</h5>
				<Form>
					<Row>
						<Col md={6} className="mb-3">
							<Form.Group id="name">
								<Form.Label>Company name</Form.Label>
								<Form.Control
									required type="text"
									placeholder="Empty"
									value={name}
									disabled={disabled}
								/>
							</Form.Group>
						</Col>
						<Col md={6} className="mb-3">
							<Form.Group id="regNumber">
								<Form.Label>Registration number</Form.Label>
								<Form.Control
									type="text"
									placeholder="Empty"
									value={registrationNumber}
									disabled={disabled}
								/>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={6} className="mb-3">
							<Form.Group id="email">
								<Form.Label>Email</Form.Label>
								<Form.Control
									type="email"
									placeholder="name@company.com"
									value={email}
									disabled={disabled}
								/>
							</Form.Group>
						</Col>
						<Col md={6} className="mb-3">
							<Form.Group id="phone">
								<Form.Label>Phone</Form.Label>
								<Form.Control
									type="text"
									placeholder="+12-345 678 910"
									value={phone}
									disabled={disabled}
								/>
							</Form.Group>
						</Col>
					</Row>

					<h5 className="my-4">Location</h5>
					<Row>
						<Col sm={9} className="mb-3">
							<Form.Group id="address">
								<Form.Label>Street</Form.Label>
								<Form.Control
									type="text"
									placeholder="Enter your home address"
									value={street}
									disabled={disabled}
								/>
							</Form.Group>
						</Col>
						<Col sm={3} className="mb-3">
							<Form.Group id="addressNumber">
								<Form.Label>Number</Form.Label>
								<Form.Control
									type="number"
									placeholder="No."
									value={streetNumber}
									disabled={disabled}
								/>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col sm={4} className="mb-3">
							<Form.Group id="city">
								<Form.Label>City</Form.Label>
								<Form.Control
									type="text"
									placeholder="City"
									value={city}
									disabled={disabled}
								/>
							</Form.Group>
						</Col>
						<Col sm={4} className="mb-3">
							<Form.Group className="mb-2">
								<Form.Label>Select Country</Form.Label>
								<Form.Select
									id="state"
									value={country}
									disabled={disabled}
								>
									<option value="0">Country</option>
									<option value="ES">Spain</option>
									<option value="DR">Germany</option>
								</Form.Select>
							</Form.Group>
						</Col>
						<Col sm={4}>
							<Form.Group id="zip">
								<Form.Label>ZIP</Form.Label>
								<Form.Control
									type="tel"
									placeholder="ZIP"
									value={zipCode}
									disabled={disabled}
								/>
							</Form.Group>
						</Col>
					</Row>
				</Form>
			</Card.Body>
		</Card>
	);
};
