import React, {useState} from "react";
import {Button, Card, Col, Form, Row} from "react-bootstrap";

export const CreateCardPayinForm = ({
	onSubmit,
}) => {
	const Countries = {
		PE: 'Peru',
	}
	const Currencies = {
		PEN: 'PEN',
		USD: 'USD',
	}
	const Documents = {
		DNI: 'DNI',
		CE: 'Foreign resident Card',
	}
	const PaymentMethods = {
		CARD: "TD"
	}

	const [country, setCountry] = useState(Countries.PE);
	const [currency, setCurrency] = useState(Currencies.PEN);
	const [documentType, setDocumentType] = useState(Documents.DNI);
	const [documentNumber, setDocumentNumber] = useState('');
	const [paymentMethod, setPaymentMethod] = useState(PaymentMethods.CARD);
	const [amount, setAmount] = useState(0);

	const handleSubmit = async (e) => {
		e.preventDefault();

		const updatedData = {
			country,
			currency,
			amount,
			documentType,
			documentNumber,
		};

		onSubmit(updatedData);
	}

	return (
		<Card border="0" className="shadow mb-4">
			<Card.Body>
				<Form onSubmit={handleSubmit}>
					<Row>
						<Col md={6} className="mb-3">
							<Form.Group className="mb-2">
								<Form.Label>Document number</Form.Label>
								<Form.Select
									id="state"value={country}
									// disabled={isDisabled}
									onChange={e => setCountry(e.target.value)}
								>
									<option value='PE'>{Countries.PE}</option>
								</Form.Select>
							</Form.Group>
						</Col>
						<Col md={6} className="mb-3">
							<Form.Group className="mb-2">
								<Form.Label>Payment Method</Form.Label>
								<Form.Select
									id="state"value={paymentMethod}
									onChange={e => setPaymentMethod(e.target.value)}
								>
									<option value={PaymentMethods.CARD}>Card</option>
								</Form.Select>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={6} className="mb-3">
							<Form.Group className="mb-2">
								<Form.Label>Document number</Form.Label>
								<Form.Select
									id="state"value={documentType}
									onChange={e => setDocumentType(e.target.value)}
								>
									<option value="DNI">{Documents.DNI}</option>
									<option value="CE">{Documents.CE}</option>
								</Form.Select>
							</Form.Group>
						</Col>
						<Col md={6} className="mb-3">
							<Form.Group id="name">
								<Form.Label>Document number</Form.Label>
								<Form.Control
									required type="text" placeholder="12345678A"
									value={documentNumber} onChange={e => setDocumentNumber(e.target.value)}
								/>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={6} className="mb-3">
							<Form.Group className="mb-2">
								<Form.Label>Currency</Form.Label>
								<Form.Select
									id="state"value={currency}
									onChange={e => setCurrency(e.target.value)}
								>
									<option value="PEN">{Currencies.PEN}</option>
									<option value="USD">{Currencies.USD}</option>
								</Form.Select>
							</Form.Group>
						</Col>
						<Col md={6} className="mb-3">
							<Form.Group id="name">
								<Form.Label>Amount</Form.Label>
								<Form.Control
									required type="text" placeholder="12345678A"
									value={amount}
									onChange={e => {
										const value = e.target.value;
										if (/^\d*$/.test(value)) {
											setAmount(Number(value));
										}
									}}
								/>
							</Form.Group>
						</Col>
					</Row>

					<div className="mt-3">
						<Button variant="gray-800" type="submit" className="mt-2 animate-up-2">
							Create pay in
						</Button>
					</div>
				</Form>
			</Card.Body>
		</Card>
	);
};
