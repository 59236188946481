import React, {createContext, useEffect, useState} from 'react';
import {request} from "../services/http.service";
import {useHistory} from "react-router-dom";
import {Routes} from "../routes";

export const InfoContext = createContext();

export const ContextProvider = ({children}) => {
	const [currentUser, setCurrentUser] = useState(null);
	const [company, setCompany] = useState(null);
	const [wallets, setWallets] = useState(null);
	const history = useHistory();

	useEffect(() => {
		initialRequest()
	}, []);

	const goToLoginPage = () => {
		history.push(Routes.SignIn.path);
	};

	const initialRequest = async () => {
		try {
			const currentUser = await request.get({path: 'public/users/me'});
			const company = await request.get({path: 'public/companies/current'});
			const wallets = await request.get({path: 'public/wallets/current'});

			setCurrentUser(currentUser.data)
			setCompany(company.data)
			setWallets(wallets.data)
		} catch (error) {
			if (error.response && error.response.status === 401) {
				goToLoginPage();
			} else {
				console.error(error);
			}
		}
	}

	return (
		<InfoContext.Provider value={{
			currentUser,
			setCurrentUser,
			company,
			wallets,
		}}>
			{children}
		</InfoContext.Provider>
	);
};
