import React from "react";
import {Button} from 'react-bootstrap';
import {Modal,} from 'react-bootstrap';
import {CreateCardPayinForm} from "components/CreateCardPayin.form";

const CreateCardPaymentModal = ({
	createCardPayment,
	handleAddWallet,
	closeModal
}) => {
	const handleCreate = (event) => {
		handleAddWallet(event);
	}

	return (
		<Modal className="create-company-dialog" centered size={'lg'} show={true}>
			<div className="modal-content p-3">
				<Modal.Header className="pb-0 border-0">
					<h5 className="fw-normal">
						{'Create new pay in'}
					</h5>
					<Button
						variant="close"
						onClick={closeModal}
					/>
				</Modal.Header>
				<Modal.Body className="pb-0">
					<CreateCardPayinForm
						onSubmit={handleCreate}
					/>
				</Modal.Body>
			</div>
		</Modal>
	)
};

export default CreateCardPaymentModal;
