import React, {useState, useRef} from "react";
import {Badge, Button, Modal, Table} from "react-bootstrap";

const companyDocumentsTitles = {
	articles: 'Articles/Memorandum of Association',
	registration: 'Certificate of Incorporation/Register',
	licence: 'License/Legal Opinion',
	addressProof: 'Proof of Address of the Company',
	bankStatement: 'Bank Statement',
	processHistory: 'Processing History of last 3 months',
	uboPassport: 'Passport (copy in color)',
	uboAddressProof: 'Proof of Address',
}

export const CompanyDocumentsModal = ({
	documents,
	uploadFile,
	closeModal
}) => {
	const companyDocumentsList = [
		'articles',
		'registration',
		'licence',
		'addressProof',
		'bankStatement',
		'processHistory',
	]
	const shareholderDocumentsList = [
		'uboPassport',
		'uboAddressProof',
	]

	const statusStyles = {
		approved: 'success',
		pending: 'secondary',
		required: 'warning',
		rejected: 'danger',
	}

	const uploadAccessStatuses = [
		'pending',
		'required',
		'rejected',
	]
	const uploadStylesStatuses = [
		'required',
		'rejected',
	]

	const formatCompanyDocuments = (docs) => companyDocumentsList.map(type => {
		const doc = docs[type]
		return {
			key: type,
			name: doc?.title || companyDocumentsTitles[type],
			status: doc?.status || 'required',
		}
	})

	const formatUboDocuments = (docs) => shareholderDocumentsList.map(type => {
		const doc = docs[type]
		return {
			key: type,
			name: doc?.title || companyDocumentsTitles[type],
			status: doc?.status || 'required',
		}
	})

	const handleCompanyButtonClick = (index) => {
		companyFileInputRefs.current[index].click();
	};

	const handleUboButtonClick = (index) => {
		uboFileInputRefs.current[index].click();
	};

	const handleUpload = async (event, documentType) => {
		console.log('upload.... ');

		const file = event.target.files[0];
		if (file) {
			const formData = new FormData();
			formData.append('documentType', documentType);
			formData.append('file', file);

			try {
				console.log('File upload in progress...');
				const updated = await uploadFile(formData, {
					'Content-Type': 'multipart/form-data',
				})
				setCompanyDocuments(formatCompanyDocuments(updated))
				setUboDocuments(formatUboDocuments(updated))
				console.log('File uploaded successfully.');
			} catch (error) {
				console.error('Error uploading file:', error);
			}
		}
	}

	const [companyDocuments, setCompanyDocuments] = useState(formatCompanyDocuments(documents));
	const [uboDocuments, setUboDocuments] = useState(formatUboDocuments(documents));
	const companyFileInputRefs = useRef([]);
	const uboFileInputRefs = useRef([]);

	return (
		<>
			<Modal className="create-company-dialog" centered size={'lg'} show={true}>
				<Modal.Header className="pb-0 border-0">
					<Modal.Title>Documents Management</Modal.Title>
					<Button
						variant="close"
						onClick={closeModal}
					/>
				</Modal.Header>
				<hr/>
				<Modal.Body>
					<h5>Company</h5>
					<Table striped bordered hover className="mb-3">
						<thead>
						<tr>
							<th>Document</th>
							<th>Status</th>
							<th>Action</th>
						</tr>
						</thead>
						<tbody>
						{companyDocuments.map((doc, index) => (
							<tr key={doc.key}>
								<td>{doc.name}</td>
								<td style={{justifyContent: 'center'}}>
									<Button
										variant={`outline-${statusStyles[doc.status]}`}
										size="sm">
										{doc.status}
									</Button>
								</td>
								<td style={{display: 'flex', justifyContent: 'center'}}>
									<Button
										disabled={!uploadAccessStatuses.includes(doc.status)}
										variant={!uploadStylesStatuses.includes(doc.status) ? 'secondary' : 'danger'}
										size="sm" onClick={(event) => handleCompanyButtonClick(index)}>
										Upload
									</Button>
									<input
										type="file"
										ref={el => companyFileInputRefs.current[index] = el}
										style={{ display: 'none' }}
										onChange={event => handleUpload(event, doc.key)}
									/>
								</td>
							</tr>
						))}
						</tbody>
					</Table>
					<br/>
					<h5>UBO/ Shareholders/Directors</h5>
					<Table striped bordered hover className="mb-3">
						<thead>
						<tr>
							<th>Document</th>
							<th>Status</th>
							<th>Action</th>
						</tr>
						</thead>
						<tbody>
						{uboDocuments.map((doc, index) => (
							<tr key={doc.key}>
								<td>{doc.name}</td>
								<td style={{justifyContent: 'center'}}>
									<Button
										variant={`outline-${statusStyles[doc.status]}`}
										size="sm">
										{doc.status}
									</Button>
								</td>
								<td style={{display: 'flex', justifyContent: 'center'}}>
									<Button
										disabled={!uploadAccessStatuses.includes(doc.status)}
										variant={!uploadStylesStatuses.includes(doc.status) ? 'secondary' : 'danger'}
										size="sm" onClick={(event) => handleUboButtonClick(index)}>
										Upload
									</Button>
									<input
										type="file"
										ref={el => uboFileInputRefs.current[index] = el}
										style={{ display: 'none' }}
										onChange={event => handleUpload(event, doc.key)}
									/>
								</td>
							</tr>
						))}
						</tbody>
					</Table>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={closeModal}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};
