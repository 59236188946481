import React, {useState} from "react";
import {Button, Card, Col, Form, Row} from "react-bootstrap";

export const UserInfoForm = ({
	user,
	onSubmit,
}) => {
	const defaultValue = '';
	const [name, setName] = useState(user.name || defaultValue);
	const [email, setEmail] = useState(user.email || defaultValue);
	const [password, setPassword] = useState(defaultValue);

	const submitForm = () => {
		const userToUpdate = {
			id: user.id,
			name,
			email,
		}
		if(password)userToUpdate.password = password;

		onSubmit(userToUpdate)
	}

	return (
		<Card border="0" className="shadow mb-4">
			<Card.Body>
				<h5 className="mb-4">General information</h5>
				<Form>
					<Row>
						<Col md={6} className="mb-3">
							<Form.Group id="name">
								<Form.Label>User name</Form.Label>
								<Form.Control required type="text" placeholder="Empty"
															value={name} onChange={e => setName(e.target.value)}
								/>
							</Form.Group>
						</Col>

						<Col md={6} className="mb-3">
							<Form.Group id="email">
								<Form.Label>Email</Form.Label>
								<Form.Control type="email" placeholder="user@email.com"
															value={email} onChange={e => setEmail(e.target.value)}
								/>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={6} className="mb-3">
							<Form.Group id="email">
								<Form.Label>New Password</Form.Label>
								<Form.Control type="password" placeholder="New Password"
															value={password} onChange={e => setPassword(e.target.value)}
								/>
							</Form.Group>
						</Col>
					</Row>

					<div className="mt-3">
						<Button onClick={submitForm} variant="gray-800" className="mt-2 animate-up-2">
							Save All
						</Button>
					</div>
				</Form>
			</Card.Body>
		</Card>
	);
};