import {Badge, Button, Card, Col, ListGroup, Row} from "react-bootstrap";
import React, {useContext} from "react";
import {InfoContext} from "../context/context.provider";

export default () => {
	const {company, wallets} = useContext(InfoContext);

	return company && (
		<>
			<div className="d-flex align-items-center justify-content-center mb-6">
				<h1 className="mb-0">Account details</h1>
				<Badge bg={company.status === 'enabled' ? 'success' : 'danger'} className="badge-lg ms-4">
					{company.status}
				</Badge>
			</div>
			<Card border="0" className="shadow">
				<Card.Body>
					{wallets && wallets.map(wallet => (
						<ListGroup.Item className="bg-transparent border-bottom py-3 px-0">

							<Row className="align-items-center">
								<Col xs="auto" className="px-4 col-auto">
									<h4 className="fs-6 text-dark mb-0">
										{wallet.provider}
									</h4>
									<div className="small d-flex mt-1">
										<small><b>{'IBAN: '}</b>{wallet.requisites.iban}</small>
									</div>
									<div className="small d-flex mt-1">
										<small><b>{'BIC: '}</b>{wallet.requisites.bic}</small>
									</div>
								</Col>
								<Col className="text-end">
            <span className="fs-6 text-dark">
							{wallet.amount} <b>{wallet.currency.toUpperCase()}</b>
            </span>
								</Col>
								<Col lg={2}>
									<Button variant="secondary" className="d-inline-flex align-items-right me-2">
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
											<path fillRule="evenodd"
														d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
														clipRule="evenodd"/>
										</svg>
										Remove
									</Button>
								</Col>
							</Row>
						</ListGroup.Item>
					))}
				</Card.Body>
			</Card>
		</>
	)
}
