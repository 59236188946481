import React, {useContext, useEffect, useState} from "react";
import { ArchiveIcon, ChevronDownIcon, ClipboardListIcon, DocumentTextIcon, PlusIcon, PresentationChartBarIcon, ShoppingBagIcon, UsersIcon } from "@heroicons/react/solid";
import {Badge, Col, Dropdown, Row} from 'react-bootstrap';
import { CompanyInfoForm } from "components/company.form";
import { UsersTable } from 'components/users.table';
import { request } from 'services/http.service';
import { convertDataToView } from 'services/date.service';
import {InfoContext} from "../context/context.provider";
import Progress from "../components/Progress";
import {Toasts} from "../components/Toast";
import {CompanyDocumentsModal} from "../components/UpdateDocuments.form";

export default () => {
  const { company } = useContext(InfoContext);
  const [users, setUsers] = useState([]);
  const [documents, setDocuments] = useState({});
  const [showDocumentsModal, setShowDocumentsModal] = useState(false)
  const [showToast, setShowToast] = useState(false);
  const [toastSettings, setToastSettings] = useState({});

  const openDocumentsModal = () => {
    setShowDocumentsModal(true)
  }
  const closeDocumentsModal = () => {
    setShowDocumentsModal(false)
  }
  const handleCloseToast = () => setShowToast(false);

  useEffect(() => {
    const start = async () => {
      if(!company)
        return

      const { data: users } = await requestCompanyUsers(company._id)
      const { data: documents } = await requestCompanyDocuments(company._id)

      setUsers(users.map(prepareData))
      setDocuments(documents)
    }

    start()
  }, [company])

  const requestCompanyUsers = (companyId) =>
    request.get({path: 'public/users/company/' + companyId})
  const requestCompanyDocuments = () =>
    request.get({path: 'public/documents'})
  const uploadFile = async (data, headers) => {
    const res = await request.post({
      path: 'public/documents/upload',
      data,
      headers
    })

    if(res?.status !== 200){
      setToastSettings({
        message: 'Documents upload failed.',
        background: 'warning'
      });

      setShowToast(true);
      return
    }

    const updatedDocuments = {
      ...documents,
      [res.data.type]: res.data
    }
    setDocuments(updatedDocuments)

    return updatedDocuments
  }

  const handleUpdateDocuments = async () => {
    closeDocumentsModal();

    setToastSettings({
      message: 'Documents updated',
      background: 'success'
    });

    setShowToast(true);
  }

  const prepareData = (data) => ({
    ...data, isSelected: false, show: true, dateCreated: convertDataToView(data.createdAt),
  })

  const complianceProgress = 60
  const complianceTitle = complianceProgress === 100
    ? 'Compliance complete'
    : 'Compliance in progress'
  const notifications = 6

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div>
          <Dropdown>
            <Dropdown.Toggle variant="secondary" className="d-inline-flex align-items-center me-2">
              <PlusIcon className="icon icon-xs me-2" />
              Account
              {notifications > 0 && (
                <Badge
                  bg="danger"
                  className="position-absolute top-0 start-100 translate-middle"
                >
                  {notifications}
                </Badge>
              )}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dashboard-dropdown dropdown-menu-start mt-2 py-1">
              <Dropdown.Item
                className="d-flex align-items-center"
                onClick={openDocumentsModal}>
                <DocumentTextIcon className="dropdown-icon text-gray-400 me-2" />
                Edit documents
              </Dropdown.Item>
              {/*<Dropdown.Divider as="div" className="my-1" />*/}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="align-items-left justify-content-center" style={{
          width: '200px'
        }}>
          <Progress
            title={complianceTitle}
            color="success"
            value={complianceProgress}
          />
        </div>

        <div className="d-flex">
          <Dropdown>
            <Dropdown.Toggle variant="gray-800">
              <ClipboardListIcon className="icon icon-xs me-2" />
              Reports
              <ChevronDownIcon className="icon icon-xs ms-1" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="dashboard-dropdown dropdown-menu-start mt-2 py-1">
              <Dropdown.Item className="d-flex align-items-center">
                <ArchiveIcon className="dropdown-icon text-gray-400 me-2" /> Products
              </Dropdown.Item>
              <Dropdown.Item className="d-flex align-items-center">
                <UsersIcon className="dropdown-icon text-gray-400 me-2" /> Customers
              </Dropdown.Item>
              <Dropdown.Item className="d-flex align-items-center">
                <ShoppingBagIcon className="dropdown-icon text-gray-400 me-2" /> Orders
              </Dropdown.Item>
              <Dropdown.Item className="d-flex align-items-center">
                <PresentationChartBarIcon className="dropdown-icon text-gray-400 me-2" /> Console
              </Dropdown.Item>

              <Dropdown.Divider as="div" className="my-1" />

              <Dropdown.Item className="d-flex align-items-center">
                <ClipboardListIcon className="dropdown-icon text-gray-800 me-2" /> All Reports
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <Row>
        <Col xs={12} xl={12}>
          {company && <CompanyInfoForm
            company={company}
          />}
        </Col>
      </Row>

      <UsersTable
        users={users.filter(u => u.show)}
        // allSelected={allSelected}
        // selectUser={selectUser}
        // deleteUsers={deleteUsers}
        // selectAllUsers={selectAllUsers}
      />
      { showDocumentsModal && <CompanyDocumentsModal
        documents={documents}
        uploadFile={uploadFile}
        closeModal={closeDocumentsModal}
      />}
      {
        showToast && <Toasts
          settings={toastSettings}
          handleCloseToast={handleCloseToast}
        />
      }
    </>
  );
};
