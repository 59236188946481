import React from "react";
import { ProgressBar } from 'react-bootstrap';

export default (props) => {
  const { label, variant, value, title, type = "label", size = "md" } = props;
  const textColor = type === "label" ? variant : "white";
  const bgColorClass = type === "tooltip" ? `bg-${variant}` : "";
  const finalVariant = value === 100 ? 'success' : 'danger'

  return (
    <div className="progress-wrapper">
      <div className="progress-info">
        <div className={`progress-${type} text-${textColor} ${bgColorClass}`}>
          {label}
        </div>
        <div className="progress-percentage">
          <span>{title}</span>
        </div>
      </div>
      <ProgressBar className={`progress-${size}`} variant={finalVariant} now={value} min={0} max={100} />
    </div>
  );
};
