import React, {
	useState,
} from 'react';
import {Route, Switch} from "react-router-dom";

import {Routes} from "routes";
import SignIn from "pages/SignIn";
import Company from "pages/Company";
import Account from "pages/Account";
import User from "pages/User";
import Transactions from "pages/Transactions";
import Sidebar from 'components/Sidebar';
import Topbar from 'components/Topbar';
import {ContextProvider} from "../context/context.provider";

const RouteWithSidebar = ({component: Component, ...rest}) => {

	const resize = () => {
		var resize = setInterval(() => {
			window.dispatchEvent(new Event('resize'));
		}, 10);
		setTimeout(function () {
			clearInterval(resize);
		}, 301);
	}

	const localStorageIsContracted = () => {
		return localStorage.getItem('sidebarContracted') !== 'false'
	}


	const [contracted, setContracted] = useState(localStorageIsContracted());
	// const [contractSidebar, setContractSidebar] = useState(localStorageIsContracted());
	const [contractSidebar, setContractSidebar] = useState(false);

	const toggleMouseOver = () => {
		if (contracted) {
			// setContractSidebar(!contractSidebar);
		}
		resize();
	};

	const toggleContracted = () => {
		setContracted(!contracted);
		setContractSidebar(!contracted);
		localStorage.setItem('sidebarContracted', !contracted);
		resize();
	};

	return (
		<Route {...rest} render={props => (
			<>
				<Sidebar
					contracted={contractSidebar}
					onMouseEnter={toggleMouseOver}
					onMouseLeave={toggleMouseOver}
				/>

				<main className="content">
					<Topbar toggleContracted={toggleContracted}/>
					<Component {...props} />
				</main>
			</>
		)}
		/>
	);
};

const PageMock = () => {
	return (
		<>
      {'Available Soon!'}
		</>
	)
}

export default () => (
	<ContextProvider>
		<Switch>
			<Route exact path={Routes.SignIn.path} component={SignIn}/>
			<RouteWithSidebar exact path={Routes.HomePage.path} component={Company}/>
			<RouteWithSidebar exact path={Routes.Company.path} component={Company}/>
			<RouteWithSidebar exact path={Routes.Account.path} component={Account}/>
			<RouteWithSidebar exact path={Routes.Transactions.path} component={Transactions}/>
			<RouteWithSidebar exact path={Routes.Beneficiaries.path} component={PageMock}/>
			<RouteWithSidebar exact path={Routes.User.path} component={User}/>
		</Switch>
	</ContextProvider>
);
